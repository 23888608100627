import $ from 'jquery';

/** import local dependencies */
import './webflow.js';

/**
 * Anonymous function is autoexecutable.
 */
(function () {

  let initialHash = location.hash;
  const $html = document.querySelector('html');

  function openModal(hash){
    closeModal();
    let $el = document.querySelector(hash);
    if ( $el && $el.classList.contains('modal') ) {
      $html.classList.add('overflow');
      $el.classList.add('open');
    }
  }

  function closeModal(){
    document.querySelectorAll('.modal').forEach($el => {
      $html.classList.remove('overflow');
      $el.classList.remove('open');
    });
  }

  function cleanHash(){
    history.pushState(null, null, '#');
  }

  window.addEventListener('hashchange', () => {
    let hash = window.location.hash;
    console.log(hash);
    if ( hash !== '' ) {
      openModal(hash);
    } else {
      closeModal();
    }
  });

  var userSelection = document.getElementsByClassName('unit-block');

  for(let i = 0; i < userSelection.length; i++) {
    userSelection[i].addEventListener("click", function(e) {
      window.location.hash = this.hash;
      e.preventDefault();
      e.stopPropagation();
    })
  }


  if (initialHash.length > 0) {
    openModal(initialHash);
  }

  document.addEventListener('keydown', function(e) {
    if (e.which === 27) {
      closeModal();
      cleanHash();
    }
  });

  if($('.modal-close').length > 0){
    $('.modal-close').on('click', function(e){
      e.preventDefault;
      closeModal();
      cleanHash();
    });
  }

  window.onclick = function(event) {
    if(event.target.classList.contains('modal')){
      closeModal();
      cleanHash();
    }
  }

  // Get all links on the page
  const links = document.querySelectorAll('a');

  // Add click event listener to each link
  links.forEach(link => {
    link.addEventListener('click', e => {
      
      // Prevent default link behavior
      //e.preventDefault();
      
       // Get the href attribute
      const href = e.target.getAttribute('href');

      // Extract the hash part using a regular expression
      const hashMatch = href.match(/#(.*)$/);

      if(hashMatch){

        // Get the extracted hash 
        const hash = hashMatch[1];
        
        // Optionally navigate to the hash 
        window.location.hash = hash;
      }

    });
  });

  if($('.faqs-item').length > 0){
    $('.faqs-title').on('click', function(){
      $('.faqs-item').removeClass('open');
      $(this).parent().toggleClass('open');
    });
  }
    

})();